import request from '@/api/request.js'

// 获取模板的列表
export function getMobanList (data) {
  return request({
    url: '/api/exportTemplate/page',
    method: 'POST',
    data
  })
}
// 获取模板字段接口
export function getFields (data) {
  return request({
    url: '/api/exportTemplate/fields',
    method: 'POST',
    data
  })
}
// 删除接口
export function delMoban (data) {
  return request({
    url: '/api/exportTemplate/delete',
    method: 'POST',
    data
  })
}
// 编辑接口
export function editMoban (data) {
  return request({
    url: '/api/exportTemplate/edit',
    method: 'POST',
    data
  })
}
// 新增接口
export function addMoban (data) {
  return request({
    url: '/api/exportTemplate/add',
    method: 'POST',
    data
  })
}
// 模板详情
export function mobanDetail (data) {
  return request({
    url: '/api/exportTemplate/detail',
    method: 'POST',
    data
  })
}
// 复制模板
export function copyMoban (data) {
  return request({
    url: '/api/exportTemplate/copy',
    method: 'POST',
    data
  })
}
// 获取运费模板的列表
export function getDeliveryList (data) {
  return request({
    url: '/api/deliveryTemplate/page',
    method: 'POST',
    data
  })
}
// 全量查询运费模板
export function getAllDelivery (data) {
  return request({
    url: '/api/deliveryTemplate/list',
    method: 'POST',
    data
  })
}
// 新增运费模板
export function deliveryAdd (data) {
  return request({
    url: '/api/deliveryTemplate/add',
    method: 'POST',
    data
  })
}
// 编辑运费模板
export function deliveryEdit (data) {
  return request({
    url: '/api/deliveryTemplate/edit',
    method: 'POST',
    data
  })
}

// 获取运费模板详情
export function getDeliveryDetail (data) {
  return request({
    url: '/api/deliveryTemplate/detail',
    method: 'POST',
    data
  })
}
// 删除运费模板
export function deliveryDel (data) {
  return request({
    url: '/api/deliveryTemplate/delete',
    method: 'POST',
    data
  })
}
// 复制运费模板
export function deliveryCopy (data) {
  return request({
    url: '/api/deliveryTemplate/copy',
    method: 'POST',
    data
  })
}
// 运费模板地区设置
export function groupMobanCities (data) {
  return request({
    url: '/api/config/groupCities',
    method: 'POST',
    data
  })
}
// 获取已启用的模板
export function listMoban (data) {
  return request({
    url: '/api/exportTemplate/list',
    method: 'POST',
    data
  })
}

// 级联组件用的省市地址
export function getCascaderCities (data) {
  return request({
    url: '/api/config/getCascaderCities',
    method: 'POST',
    data
  })
}
